import { useState, useEffect } from 'react'
import { Input } from './Input.js'
import * as defs from './defs/Modal.defs.js'

export const Modal = ({ selected, set_selected, faturas, set_faturas }) => {
  const [insert, set_insert] = useState(null)
  const [modal, set_modal] = useState(null)

  useEffect(() => {
    if (!insert || !modal || !selected) return
    modal.addEventListener('click', ({ target }) => {
      !insert.contains(target) && set_selected(null)
    })
  }, [insert, selected, set_selected, modal])

  useEffect(() => {
    document.addEventListener('keydown', ({ keyCode }) => {
      keyCode === 27 && set_selected(null)
    })
  })

  if (!selected) return null

  return (
    <Container elemRef={set_modal}>
      <Insert elemRef={set_insert}>
        <Name>{selected.name}</Name>
        <Seller>{selected.seller}</Seller>
        {Object.entries(inputs).map((input) => (
          <Input
            key={input[0]}
            input={input}
            data={selected}
            datas={faturas}
            set_datas={set_faturas}
            data_type="faturas"
          />
        ))}
      </Insert>
    </Container>
  )
}

const { Container, Insert, Name, Seller, Field, TextArea } = defs

const inputs = {
  reference: { component: Field, placeholder: 'FS 345/2000' },
  code: { component: Field, placeholder: 'hd7r' },
  infos: { component: TextArea, placeholder: 'Anything else?' },
}
