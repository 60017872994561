import { useState, useEffect } from 'react'
import { Icon } from './icons.js'
import { Metrics } from './Metrics.js'
import { Category } from './Faturas.js'
import { Modal } from './Modal.js'
import { SignIn, on_success, sign_out } from './SignIn.js'
import { fetch_data } from './data.js'
import { Router } from '@reach/router'
import { Div } from './flags.js'
import * as components from './defs/Components.defs.js'
import * as defs from './defs/Home.defs.js'

const App = () => {
  const [auth, set_auth] = useState(null)
  const [message, set_message] = useState(null)

  useEffect(() => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          client_id:
            '485577241834-0ag9quhgqbt5c06pfl9pkqq31druo26e.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
        })
        .then(() => {
          const authInstance = window.gapi.auth2.getAuthInstance()
          if (authInstance.isSignedIn.get()) {
            on_success(authInstance, set_auth, set_message)
          }
          set_auth(authInstance)
          authInstance.isSignedIn.listen(() => {
            const updatedAuth = window.gapi.auth2.getAuthInstance()
            set_auth(updatedAuth)
          })
        })
    })
  }, [])

  return (
    <Router>
      <SignIn
        path="/"
        auth={auth}
        set_auth={set_auth}
        message={message}
        set_message={set_message}
      />
      <Home path="/faturas" auth={auth} set_auth={set_auth} />
    </Router>
  )
}

const Home = ({ auth, set_auth }) => {
  const [mode, set_mode] = useState('grid')
  const [faturas, set_faturas] = useState([])
  const [selected, set_selected] = useState(null)

  useEffect(() => {
    if (!auth) return
    if (!auth.isSignedIn.get()) sign_out(auth)
  }, [auth])

  useEffect(() => {
    if (!auth || !auth.isSignedIn.get()) return
    fetch_data('faturas', set_faturas)
  }, [faturas.length, set_faturas, auth])

  if (!auth)
    return (
      <Loader>
        <Holder>Hold on for a sec</Holder>
        <Div w350 h350 className="loader" />
      </Loader>
    )

  if (!auth.isSignedIn.get()) {
    return (
      <Unauthorized>
        <Title>Oopsy!</Title>
        <Text>
          Seems like you're trying to sneak in an unauthorized area. <br />
          You have to be signed in with your Google account.
        </Text>
        <a href="/">
          <Button mt90>Go back to sign in page</Button>
        </a>
      </Unauthorized>
    )
  }

  return (
    <Page>
      <User>
        <Profile>{auth.currentUser?.get().getBasicProfile().getName()}</Profile>
        <SignOut onClick={() => sign_out(auth, '/')}>Sign out</SignOut>
      </User>
      <Header>
        <Title>Faturas</Title>
        <Modes>
          {modes.map((type) => (
            <Icon key={type} type={type} mode={mode} set_mode={set_mode} />
          ))}
        </Modes>
        <Link
          href="https://faturas.portaldasfinancas.gov.pt/consultarDocumentosAdquirente.action"
          target="_blank"
        >
          Portal das Finanças
        </Link>
      </Header>
      <Metrics faturas={faturas} />
      {categories.map((category, i) => (
        <Category
          key={category}
          faturas={faturas}
          set_faturas={set_faturas}
          selected={selected}
          set_selected={set_selected}
          category={category}
          mode={mode}
        />
      ))}
      <Modal
        selected={selected}
        faturas={faturas}
        set_faturas={set_faturas}
        set_selected={set_selected}
      />
    </Page>
  )
}

const { Page, Title, Header, Button, Text, Link } = components
const { User, Profile, SignOut, Modes, Unauthorized, Loader, Holder } = defs

const categories = ['pending', 'unregistered', 'registered']
const modes = ['grid', 'rows']

export default App
