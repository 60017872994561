import { update_data } from './data.js'

export const Input = ({ input, mode, data, datas, set_datas, data_type }) => {
  const [key, { component, placeholder, classes }] = input
  const Component = component
  const other_datas = datas.filter((f) => f.id !== data.id)
  const flags =
    mode &&
    Object.assign({}, ...classes[mode].map((classe) => ({ [classe]: true })))

  return (
    <Component
      spellCheck="false"
      defaultValue={data[key]}
      text_center={mode === 'grid'}
      placeholder={placeholder}
      onChange={({ target }) => {
        set_datas([...other_datas, { ...data, [key]: target.value }])
        update_data(
          `${data_type}/${data.id}`,
          set_datas,
          { [key]: target.value },
          500,
        )
      }}
      {...flags}
    />
  )
}
