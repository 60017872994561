import { Component } from '../flags.js'

export const Section = Component.fs18.mb25.pv10.uppercase.mono.ls2.bb.div()
export const Button = Component.order5.ba.hover_b_grey3.anim_border.w110.text_center.b_rad20.c_pointer.pv5.ph15.uppercase.fs10.ls2.b_grey2.div()
export const Container = Component.mt100.div()
export const Row = Component.c_pointer.flex.ai_baseline.bb.b_grey2.pv20.div()
export const Grid = Component.mt50.flex.flex_wrap.jc_between.div()
export const Card = Component.c_pointer.w15p.ai_center.ba.pt15.pb30.ph15.mb35.b_grey2.flex.flex_column.min_h240.div()
export const Infos = Component.flex.jc_between.w100p.uppercase.fs10.div()
export const Add = Component.ml15.fs12.uppercase.mono.ls1.div()
export const Delete = Component.o0.anim_opacity.grey5.ph30.c_pointer.absolute.div()
export const DatePicker = Component.relative.div()
export const Day = Component.fs11.mono.ls1.ba0.ol_none.pa0.w50.input()
export const Hider = Component.absolute.bg_white.w10.h20.div()
export const Scope = Component.uppercase.fs10.ls2.c_pointer.div()
export const Name = Component.fs20.w100p.ba0.ol_none.input()
export const Seller = Component.mono.grey4.fs12.w100p.ba0.ol_none.h_auto.textarea()
export const Price = Component.order4.fs15.mono.ls1.w100p.ba0.ol_none.input()