import { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { Page, Title, Button, Message } from './defs/Components.defs.js'

export const SignIn = ({ auth, set_auth, message, set_message }) => {
  const [button, setButton] = useState(null)

  useEffect(() => {
    if (!auth || !button) return
    if (auth.isSignedIn.get()) navigate('/faturas')
    auth.attachClickHandler(
      button,
      {},
      () => on_success(auth, set_auth, set_message),
      (error) => on_failure(error, set_message),
    )
  }, [button, auth, set_auth, set_message])

  return (
    <Page flex flex_column ai_center>
      <Title mb170>Faturas</Title>
      <Button elemRef={setButton}>Sign in with Google</Button>
      <Message>{message}</Message>
    </Page>
  )
}

export const sign_out = (auth, redirect) => {
  auth.signOut().then(() => {
    auth.disconnect()
    fetch('/api/sign-out', {
      credentials: 'include',
    })
    redirect && navigate(redirect)
  })
}

export const on_success = async (auth, set_auth, set_message) => {
  const googleUser = auth.currentUser.get()
  const token = googleUser.getAuthResponse().id_token
  const profile = googleUser.getBasicProfile()

  const { status } = await fetch('/api/sign-in', {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  })

  if (status === 201) {
    navigate('/faturas')
  } else {
    set_message(
      `Sorry ${profile.getName()}, but you're not an authorized user! 
      Switch to a valid Google account to get in.`,
    )
    sign_out(auth)
  }
}

const on_failure = (error, set_message) =>
  set_message(error, 'Failure to sign in in Google')
