import { Component } from '../flags.js'

export const Caption = Component.capitalize.mb10.mono.fs13.div()
export const Data = Component.fs50.mono.grey6.div()
export const Sum = Component.ml15.black.span()
export const Invoices = Component.flex.flex_wrap.w100p.jc_between.div()
export const Item = Component.mt30.b_grey2.w30p.flex.pb10.bb.jc_between.div()
export const Description = Component.mono.w35p.fs14.ol_none.ba0.pa0.input()
export const Client = Component.w45p.mono.fs14.grey4.ol_none.ba0.pa0.input()
export const Amount = Component.w20p.mono.sapphire4.fs17.ol_none.ba0.text_right.input()
export const Delete = Component.absolute.pl25.c_pointer.div()